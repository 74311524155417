import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import { LazyBlocksFactory } from '../components/LazyBlocksFactory/LazyBlocksFactory'
import MainGrid from '../components/styling/MainGrid'
import {UseCaseCard} from '../components/UseCases/UseCaseCard'
import styled from 'styled-components'
interface Props {
  data: any
  pageContext: any
}

const UseCases = ({ data, pageContext }: Props) => {
  const { wpPage, allWpUseCase } = data

  // const lazyBlocks = parse(wpPage.lazy_data)
  const renderedBlocks = LazyBlocksFactory(wpPage.lazy_data, wpPage.title)

  return (
    <Layout>
     {renderedBlocks.map((v) => v)}

      <MainGrid>
        <CardContainer>

          {allWpUseCase.nodes.map((v, i) => {
            return (
              <UseCaseCard
                key={'use-case-card-' + i}
                useCase={v}
              />
            )
          })}
        </CardContainer>
      </MainGrid>
    </Layout>
  )
}

const CardContainer = styled.div`
  cursor: pointer;
  width: 100%;
  margin: 0 auto;
  padding-top: 28px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 30px;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 20px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(3, 1fr);
    padding-top: 50px;
    grid-row-gap: 30px;
    grid-column-gap: 30px;
  }
`

export default UseCases

export const pageQuery = graphql`
  query useCases {
    wpPage(title: { eq: "Use Cases" }) {
      id
      title
      lazy_data
    }
    allWpUseCase {
      nodes {
        title
        slug
        use_cases_fields{
            description
        }
      }
    }
  }
`
